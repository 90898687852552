<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h2 class="f-alfa primary--text">Welcome, {{ USER.user.name }}</h2>
      </v-flex>
      <v-flex xs12 md8 class="mt-5 pa-2">
        <!-- <div class="text-right">
          <v-btn class="white--text primary" @click="addTask" rounded
            ><v-icon class="mr-2">mdi-plus</v-icon>Add Task</v-btn
          >
        </div> -->
        <v-tabs v-model="tab">
          <v-tab>My Tasks</v-tab>
          <v-tab>Tasks Assigned</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-layout row wrap v-if="myTasks.length > 0" class="pa-5">
              <v-flex
                xs12
                v-for="item in myTasks"
                :key="item._id"
                class="grey darken-4 pa-5 mt-5"
                style="border-radius: 10px"
              >
                <h3 class="primary--text">{{ item.title }}</h3>
                <p class="caption accent--text">{{ item.created }}</p>
                <p>{{ item.description }}</p>
                <v-layout row wrap>
                  <v-flex xs12 lg4 class="mt-2 mb-2">
                    <div class="d-flex">
                      <img
                        :src="item.assigned_to.display_picture"
                        alt=""
                        style="width: 48px; height: 48px; border-radius: 24px"
                        v-if="item.assigned_to.display_picture"
                      />
                      <div
                        style="
                          width: 48px;
                          height: 48px;
                          background-color: #212121;
                          border-radius: 24px;
                        "
                        v-else
                      ></div>
                      <div class="pl-3">
                        <p class="pa-0 ma-0">{{ item.assigned_to.name }}</p>
                        <p class="pa-0 ma-0 accent--text">
                          {{ item.assigned_to.department.name }}
                        </p>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs12 lg6 class="mt-2 mb-2">
                    <h4>
                      <span class="accent--text">PROJECT : </span>
                      <span v-if="item.project">{{ item.project.name }}</span>
                      <span v-else>N/A</span>
                    </h4>
                    <h4>
                      <span class="accent--text">DEADLINE : </span>
                      {{ item.end_date }}, {{ item.end_time }}
                    </h4>
                  </v-flex>

                  <v-flex xs12 lg2 class="mt-2 mb-2">
                    <h4>
                      <span class="accent--text">STATUS : </span>
                      {{ item.status }}
                    </h4>
                    <h4>
                      <span class="accent--text">COMPLETION : </span>
                      {{ item.completion }}%
                    </h4>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-else>
              <v-flex xs12>
                <p class="pa-10">No Available Tasks</p>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout row wrap class="pa-5">
              <v-flex
                xs12
                v-for="item in tasks"
                :key="item._id"
                class="grey darken-4 pa-5 mt-5"
                style="border-radius: 10px"
              >
                <h3 class="primary--text">{{ item.title }}</h3>
                <p class="caption accent--text">{{ item.created }}</p>
                <p>{{ item.description }}</p>
                <v-layout row wrap>
                  <v-flex xs12 lg4 class="mt-2 mb-2">
                    <div class="d-flex">
                      <img
                        :src="item.assigned_to.display_picture"
                        alt=""
                        style="width: 48px; height: 48px; border-radius: 24px"
                        v-if="item.assigned_to.display_picture"
                      />
                      <div
                        style="
                          width: 48px;
                          height: 48px;
                          background-color: #212121;
                          border-radius: 24px;
                        "
                        v-else
                      ></div>
                      <div class="pl-3">
                        <p class="pa-0 ma-0">{{ item.assigned_to.name }}</p>
                        <p class="pa-0 ma-0 accent--text">
                          {{ item.assigned_to.department.name }}
                        </p>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs12 lg6 class="mt-2 mb-2">
                    <h4>
                      <span class="accent--text">PROJECT : </span>
                      <span v-if="item.project">{{ item.project.name }}</span>
                      <span v-else>N/A</span>
                    </h4>
                    <h4>
                      <span class="accent--text">DEADLINE : </span>
                      {{ item.end_date }}, {{ item.end_time }}
                    </h4>
                  </v-flex>

                  <v-flex xs12 lg2 class="mt-2 mb-2">
                    <h4>
                      <span class="accent--text">STATUS : </span>
                      {{ item.status }}
                    </h4>
                    <h4>
                      <span class="accent--text">COMPLETION : </span>
                      {{ item.completion }}%
                    </h4>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
      <v-flex xs12 md4 class="mt-5 pa-2">
        <v-card
          class="mx-auto text-center"
          color="grey darken-4"
          dark
          max-width="600"
        >
          <v-card-text>
            <v-sheet color="grey darken-3">
              <v-sparkline
              :gradient="gradient"
                :value="perfomance"
                color="rgba(255, 255, 255, .7)"
                height="100"
                padding="24"
                stroke-linecap="round"
                smooth
              >
                <template v-slot:label="item"> {{ item.value }} %</template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h5 font-weight-thin">
              Perfomance - Last 14 Days
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-center">
            <v-btn block text> Go to Report </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import Axios from "axios";
import { BASE_URL } from "../../config";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: null,
      tasks: [],
      myTasks: [],
      perfomance: [55, 78, 85, 95, 90, 98, 80, 75, 80, 87, 99, 88, 60, 40],
      gradient: ['#f72047', '#ffd200', '#1feaea'],
    };
  },
  mounted() {
    this.fetchTasks();
  },
  computed: { ...mapGetters(["USER"]) },

  methods: {
    async fetchTasks() {
      const headers = {
        Authorization: this.USER.token,
      };
      {
        let url = BASE_URL + "/task?type=1";
        let { data } = await Axios.get(url, { headers });
        this.tasks = data;
      }
      {
        let url = BASE_URL + "/task?type=0";
        let { data } = await Axios.get(url, { headers });
        this.myTasks = data;
      }
    },
    addTask() {
      this.$router.push("/add-task");
    },
  },
};
</script>